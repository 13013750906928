// set type
@font-face {
  font-family: 'Taki Bold';
  src: url('../../fonts/Taki-bold.woff') format('woff'),
      url('../../fonts/Taki-bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Spartan';
  src: url('../../fonts/Spartan-Regular.woff') format('woff'),
       url('../../fonts/Spartan-Regular.woff2') format('woff2');
  font-weight: 400; /* Regular */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Spartan';
  src: url('../../fonts/Spartan-Bold.woff') format('woff'),
       url('../../fonts/Spartan-Bold.woff2') format('woff2');
  font-weight: 700; /* Bold */
  font-style: normal;
  font-display: swap;
}


$menu-font: 'Spartan', sans-serif;
$heading-font: 'Spartan', sans-serif;
$subheading-font: 'Spartan', sans-serif;
$paragraph-font: 'Spartan', sans-serif;
$display-font: 'Taki Bold', serif;
$light-weight: 300;
$normal-weight: 400;
$medium-weight: 500;
$heavy-weight: 700;
$ultra-weight: 800;

:root {
  font-size: 16px;
  @include media-breakpoint-up(lg) {font-size: 100%;}
  @include media-breakpoint-up(xl) {font-size: 110%;}
  @include media-breakpoint-up(xxl) {font-size: 120%;}
  // @include media-breakpoint-up(xxxl) {font-size: 140%;}
  // @media screen and (min-width: 2400px) {font-size: 170%;}
  // @media screen and (min-width: 3000px) {font-size: 200%;}
  // @media screen and (min-width: 5000px) {font-size: 275%;}
}

body {
  font-optical-sizing: auto;
  font-family: $paragraph-font;
  font-size:16px;
}

p {
  font-size: 1rem;
  font-weight: $normal-weight;
  margin-bottom: 1.25rem;
  text-wrap: pretty;
  font-weight:400;
}

a {
  color: $brand-primary;
  font-weight: $heavy-weight;
  text-decoration: none;
  transition-duration: 350ms;
  &:hover {
    text-decoration: none;
    color: $brand-secondary;
  }
}

/*
** font scaling: https://type-scale.com/
*/
h1, h2, h3, h4, h5, h6,
.h1,.h2,.h3,.h4,.h5,.h6,
.display-1, .display-2, .display-3 {
  font-family: $heading-font;
  font-weight: $heavy-weight;
  margin-bottom: .5rem;
  line-height: 1.2;
  letter-spacing:-.025em;
}

h1, .h1 {
  font-size: 3.052rem;
}
h2, .h2 {
  font-size: 1.75rem;
  @include media-breakpoint-up(md) {
    font-size: 2.441rem;
  }
}
h3, .h3 {font-size: 1.953rem;}
h4, .h4 {font-size: 1.563rem;}
h5, .h5 {font-size: 1.25rem;}
h6, .h6 {font-size: 1rem;}

h1, .h1 {
  font-size: 2.25rem;
  @include media-breakpoint-up(md) {
    font-size: 3.052rem;
  }
  &.shrink-xs {
    @include media-breakpoint-down(md) {
      font-size:2rem!important;
    }
  }
}
@media (max-width:374px) {
  h1.shrink-xs, .h1.shrink-xs { 
    font-size:1.5rem!important;
  }
}
h2, .h2 {font-size: 2.441rem;}
h3, .h3 {font-size: 1.953rem;}
h4, .h4 {font-size: 1.563rem;}
h5, .h5 {font-size: 1.25rem;}
h6, .h6 {font-size: 1rem;}

.sub-heading {
  font-size: 1.25rem;
  font-family: $subheading-font;
  text-transform: unset;
  letter-spacing: 0;
  font-weight: $heavy-weight;
  font-style: unset;
}

small, .text_small {font-size: 0.9em !important;}

.ff-display {
  font-family: $display-font !important;
  letter-spacing:.01em;
  &.h1:not(.fs-xl) {
    @include media-breakpoint-up(md) {
      font-size: 4em;
    }
  }
}
.fs-1 {
  @include media-breakpoint-down(md) {
    font-size:3rem!important;
  }
  &.shrink-xs {
    @include media-breakpoint-down(md) {
      font-size:2rem!important;
    }
  }
}
.fs-xl {
  font-size: 2.75rem !important;
  line-height: .9em !important;
  @include media-breakpoint-up(md) {
    font-size:7vw!important;
  }
}
.fs-xs {
  font-size:11px!important;
  @include media-breakpoint-up(xxl) {
    font-size:13px!important;
  }
}

.text-underline {
  text-decoration:underline;
}


//xxs mobile
@media (max-width: 390px) {
  h1, .h1 { font-size: 2rem; }
  h2, .h2 { font-size: 1.953rem; }
  h3, .h3 { font-size: 1.562rem; }
  h4, .h4 { font-size: 1.25rem; }
  h5, .h5 { font-size: 1rem; }
  h6, .h6 { font-size: 0.8rem; }
  .sub-heading {
    font-size: calc(1.6rem * 0.8);
    font-family: $subheading-font;
    text-transform: unset;
    letter-spacing: 0;
    font-style: unset;
  }
  .fs-xl {
    font-size: 2.5rem!important;
    line-height: .9em !important;
  }
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .hero-content {
    h1, .h1 {
      font-size:2.2rem;
    }
  }
}
@media (max-width: 374px) {
  h1, .h1 { font-size: 1.7rem; }
  h2, .h2 { font-size: 1.55rem; }
  h3, .h3 { font-size: 1.55rem; }
  h4, .h4 { font-size: 1.25rem; }
  h5, .h5 { font-size: 1rem; }
  h6, .h6 { font-size: 0.8rem; }
  .sub-heading {
    font-size: calc(1.6rem * 0.8);
    font-family: $subheading-font;
    text-transform: unset;
    letter-spacing: 0;
    font-style: unset;
  }
  .fs-xl {
    font-size: 1.7rem!important;
    line-height: .9em !important;
  }
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .hero-content {
    h1, .h1 {
      font-size:2.2rem;
    }
  }
}
