.offcanvas {
  background-attachment:fixed!important;
  z-index:99999;
  .offcanvas-logo {
    max-height:40px;
  }
  .navbar-nav {
    .nav-link {
      color:$dark;
      font-family:$heading-font;
      font-weight:bold;
      padding-top:.75rem;
      padding-bottom:.75rem;
      text-align:center;
      font-size:1.25em;
      text-transform:none;
      letter-spacing: -.025em;
    }
    .dropdown-menu {
      position:relative!important;
      transform:none!important;
      background:$light;
      border:0;
      border-radius:0px;
      line-height: 1.5em;
      font-size: 1.25rem;
      .nav-item {
        text-align:center;
        font-family:$paragraph-font;
        &.menu-item-has-children {
          i,svg {
            display:none!important;
          }
        }
        .dropdown-item {
          color:$dark;
          text-wrap:unset!important;
          font-size:1rem;
          &:hover, &:focus, &:active {
            background:transparent;
            color:$brand-primary;
            outline:0!important;
            box-shadow:unset!important;
          }
        }
        svg.color-white, svg {
          fill:$brand-primary;
          color:$brand-primary;
        }
      }
    }
  }
  svg.svg-inline--fa.fa-store.fa-w-20.me-1.color-white {
    color: $brand-primary !important;
    fill: $brand-primary !important;
  }
  .search-bar {
    margin-top:3em;
    margin-bottom:3em;
    .custom-search-input {
      width:100%;
    }
  }
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -5.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}
