.rpb-cards {
  .card {
    border:0;
    border-radius:1em;
    p {
      &:last-of-type {
        margin-bottom:0;
      }
    }
    .card-header {
      background:unset;
      border-bottom:unset;
    }
    .card-img-top {
      border-radius:1em 1em 0px 0px;
    }
    .card-body {
      border-radius:0 0 1em 1em;
    }
  }
  // Vertical Align Class
  .vertically-aligned-card {
    display:flex;
    flex-direction:column;
    justify-content:center;
  }
  // Image Classes
  .bg-img-card {
    display:none;
  }
  // Default Slider Styles
  &.bootstrap-cards-slider {
    .splide__slider {
      .rpb-slider-arrows {
        .splide__arrow--prev {
          left:0;
        }
        .splide__arrow--next {
          right:0;
        }
      }
      .splide__track {
        margin-left: 1rem;
        margin-right: 1rem;
        overflow:visible;
        @include media-breakpoint-up(md) {
          margin-left:3rem;
          margin-right:3rem;
        }
      }
      .splide__pagination {
        bottom:-2.5rem;
      }
    }
  }
}
.splide-track-0 {
  .splide__track {
    overflow:hidden!important;
    margin-left: -2em!important;
    margin-right: -2em!important;
  } 
}
