body {
  background: $brand-primary;
  overflow-x: hidden;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}


// Safari Fixes

// phone link
a[href^=tel] {
  text-decoration: inherit;
  color: inherit;
}
/** custom classes */
*::selection {
  background:$orange;
  color:$brand-primary;
}
.cp-0 {
  padding-left:0px!important;
  padding-right:0px!important;
  .row {
    margin-left:0px!important;
    margin-right:0px!important;
  }
}
.background-size-contain-sm {
  background-size: 250px !important;
}
.overflow-x-hidden {
  overflow:hidden;
  @include media-breakpoint-up(sm) {
    overflow:unset;
    overflow-x:hidden;
  }
}
.content-sticky-top {
  position: sticky;
  top: 150px;
}
.background-red-pattern {
  background:url(../../images/red-pattern.jpg);
  background-size:cover;
}
.bolts {
  position:relative;
  display:inline-block;
  &:before {
    content: "";
    height: 1.25em;
    width: 1.25em;
    background: url(../../images/bolt-left.png);
    background-size: contain;
    position: relative;
    margin-right: 1.5em;
    background-repeat: no-repeat;
    display: inline-block;
    top: .25em;
    @include media-breakpoint-down(lg) {
      margin: auto;
      transform: rotate(90deg);
      margin-bottom: .5em;
      display: block;
    }
  }
  &:after {
    content: "";
    height: 1.25em;
    width: 1.25em;
    background: url(../../images/bolt-right.png);
    background-size: contain;
    position: relative;
    margin-left: 1.5em;
    background-repeat: no-repeat;
    display: inline-block;
    top: .25em;
    @include media-breakpoint-down(lg) {
      display:none;
    }
  }
  &.color-light {
    &:before {
      background: url(../../images/bolt-left-light.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
    &:after {
      background: url(../../images/bolt-right-light.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
.background-dark + .background-dark {
  margin-top:-1px;
}
.text-columns-2 {
  @include media-breakpoint-up(md) {
    columns: 2;
    column-gap: 1.5em;
  }
}
.background-size-contain {
  background-size: 100% auto !important;
  background: #fff;
  background-position: center !important;
}
.background-size-cover {
  background-size:cover!important;
}
.icon-sm {
  padding: .5em;
  margin-right: .5em;
  background: rgba(255, 255, 255, 0.15);
  height: 2em;
  width: 2em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: .5em;
}
.grayscale {
  filter: grayscale(1);
}
.cp-0 {
  padding-left:0px;
  padding-right:0px;
  .row {
    margin-left:0px;
    margin-right:0px;
  }
}
.cp-xs-0 {
    @include media-breakpoint-down(md) {
    padding-left:0px;
    padding-right:0px;
    > .row {
      margin-left:0px;
      margin-right:0px;
      .cp-px-0 {
        padding-left:0px;
        padding-right:0px;
      }
    }
  }
}
  
.brand-glow {
  position: relative; /* Ensure the pseudo-element is positioned correctly */
  z-index: 1; /* Place the element above the pseudo-element */
}

.brand-glow::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* Place the pseudo-element behind the element */
  background: linear-gradient(to bottom, #ff5e5e 0%, #ffc4e1 50%, #fff 100%);
  filter: blur(10px); /* Adjust the blur to create a shadow effect */
  border-radius: 5px; /* Match the border radius of the element if necessary */
  /* Adjust the shadow size */
  transform: scale(1.1);
}
select#location-selector {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none; /* Removes border */
  padding: 0; /* Removes padding */
  font-size: 11px; /* Inherit font size */
  color: inherit; /* Inherit font color */
  font-weight:bold;
  font-family:$subheading-font;
  text-transform:unset;
  background: url('../../images/caret-down-solid-white.png') no-repeat right center; /* Custom arrow icon */
  background-size: 12px; /* Adjust as needed */
  padding-right: 20px; /* Add space for the icon */
  margin-bottom: -.25em;
  @include media-breakpoint-up(xl) {
    font-size: 13px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 16px;
  }
  &:focus, &:active, &:hover {
    outline:0!important;
    box-shadow:0!important;
  }
}
.text-shadow-white {
  text-shadow:0px .5em 1em #fff!important;
  @include media-breakpoint-up(md) {
    text-shadow: 0px 0px 0.5em #fff, 0px 0px 1em #fff,  0px 0px 2em rgba(255,255,255,0.5);
  }
}
.primary-gradient-overlay:before {
  content: '';
  background: linear-gradient(to bottom, rgba($orange, .75) 0%, rgba($brand-primary, 1) 90%) !important;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
}
.primary-overlay:before {
  content: '';
  background: linear-gradient(to bottom, rgba($brand-primary, .75) 0%, rgba($brand-primary, 1) 90%) !important;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
}
.brand-gradient-overlay-offcanvas:before {
  content: '';
  background: linear-gradient(to bottom, rgba(255, 94, 94, 0.9) 0%, rgba(255, 196, 225, 0.9) 50%, rgba(255, 255, 255, 0.9) 100%) !important;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
}
.background-teal-overlay {
  position:relative;
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba($teal, .85);
    left: 0;
    top:0;
    z-index:1;
  }
  .row {
    position:relative;
    z-index:1;
  }
}
.notunder1500 {
  .btn {
    width: 100%;
    border-radius: 0!important;
  }
}

.background-fixed {
  @include media-breakpoint-up(lg) {
    background-attachment: fixed !important;
  }
}

.cursor-hover {
  &:hover {
    cursor:pointer;
  }
}
.hover-grow {
  transition:all .2s ease-out;
  transform:scale(1);
  &:hover {
    transition:all .2s ease-out;
    transform:scale(1.05);
  }
}
.hover-shrink {
  transition:all .2s ease-out;
  transform:scale(1);
  &:hover {
    transition:all .2s ease-out;
    transform:scale(.95);
    border: solid 2px $pink!important;
  }
  &.hover-dark:hover {
    border: solid 2px $dark!important;
  }
}
.drop-shadow-red {
  filter: drop-shadow(0px 1em 2em rgba(211, 74, 74, 0.35));
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.min-height {
  min-height: 56vw;

  @include media-breakpoint-up(md) {
    min-height: 450px;
  }
}
@media (max-width:374px) {
  .min-height {
    min-height: 85vw;
  }
}
img[alt="amp it up"],
img[src*="natures-wellness"] {
  border-radius: 1em;
  width: 100%;
}
hr {
  background-color:rgba(0,0,0,0.5);
}

.overflow-hidden {
  overflow: hidden!important;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.grow-bkg {
  transition: all 0.2s ease-in;

  &.grow-this-bkg,
  .grow-this-bkg {
    background-size: 100%!important;
    transition: all 0.2s ease-in;

    &:hover {
      transition: all 0.2s ease-in;
      background-size: 110%!important;
    }
  }
}
.check-list, #tabs .tab-content ul {
  padding-left:0px;
  li {
    list-style:none;
    margin-bottom:.5em;
  }
}

.notunder1500 {
  display: none;
}
@media (min-width:1800px) {
  .notunder1500 {
    display: block;
  }
}

.negative-top {
  margin-top: -100px;
}
.negative-top-md {
  @include media-breakpoint-up(md) {
    margin-top: -100px;
  }
}
.negative-top-xl {
  @include media-breakpoint-up(xl) {
    margin-top: -100px;
  }
}
// Images

img {
  max-width: 100%;
}

// Util
.img-invert-white {
  filter: brightness(0) invert(1);
}

.grayscale {
  filter: grayscale(1);
}

.z-index-1 {
  z-index:1;
}
.z-index-2 {
  z-index:2;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, $dark, darken($dark, 10%));
  opacity: 0.85;

  &.light-fade {
    background: linear-gradient(45deg, #000, lighten(#000,10%), #000);
    opacity: 0.6;
  }
}

.sticky {
  @include media-breakpoint-up(md) {
    position: sticky !important;
    top: 160px !important;
  }
}
// Content Images

.img-cover-col {
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
// RESPONSIVE FIXES

.sticky-top {
  @include media-breakpoint-down(sm) {
    position: static;
  }
}

// Move accessibility button to bottom

.pojo-a11y-toolbar-toggle {
  bottom: 0;
}
.circle-icon {
	height: 1.5em;
	width: 1.5em;
	border-radius: 1em;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5em;

	svg {
		font-size: .75em !important;
	}
}
.hr-styled > ul,
.hr-styled > ol,
ul.hr-styled,
ol.hr-styled,
.default-content-wrap div > ul,
.tab-content > ul {
  padding-left: 0px;
  margin-bottom: 2em;

  > li {
    list-style: none;
    padding-bottom: .75em;
    border-bottom: solid 1px;
    margin-bottom: .75em;
    font-size: 1rem;

    > b {
      display: block;
    }

    &:first-child {
      padding-top: .5em;
      border-top: solid 1px;
    }
  }
}
.tabs {
	.nav-item {
    background:$light;
    border:0!important;
    border-radius:.5em;
		a {
			opacity: 1 !important;
			color: $brand-primary;
			font-size: 1rem !important;
			padding-right: 1em !important;
			width:100%;
			z-index:1;
			position:relative;
      letter-spacing:-.025em;
      transition:all .2s ease-out;
      @include media-breakpoint-up(lg) {
        padding-right: 3em !important;
      }

			* {
        @include media-breakpoint-up(lg) {
          font-size: 1.25rem !important;
          margin-bottom: 0px;
        }
			}
		}

		.circle-icon {
			border: solid 1px $dark;
			background: transparent;
      position:relative;
			cursor: pointer;
			flex-grow: 0;
			flex-shrink: 0;
			right: 0;
			z-index:0;

			&:before {
				content: '+';
				color: $dark;
				position: absolute;
        transform: translateY(.125em);
			}
		}

		&.active, &:hover {
			background:$orange;
      color:$brand-primary;
			.circle-icon {
				background: $brand-primary;
        
				&:before {
					content: '-';
          color:$light!important;
          transform: translateY(0em);
				}
			}
		}
    &:hover {
      a {
        transition:all .5s ease-out;
        letter-spacing:.0125em;
      }
    }
	}
  .tab-content {
    h1,.h1,h2,.h2,h3,.h3 {
      font-size:1.5em;
    }
  }
}
.sticky-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
}

//modal 
.modal {
  z-index:9999;
}
.modal-dialog {
  @include media-breakpoint-down(xl) {
    max-width:100%; 
  }
}
