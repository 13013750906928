.rpb-accordion {
  .accordion-item {
    border: 0;
    background: transparent !important;
    border-bottom: 3px solid rgba(0,0,0,0.1)!important!important;
    &:last-of-type {
      border-bottom:0 !important;
    }

    .accordion-body {
      padding: 1.5rem 0px;
      color:#fff!important;
      p {
        &:last-of-type {
          margin-bottom:0;
        }
      }
      a {
        color:$accent;
      }
    }
    .accordion-button {
      background:transparent!important;
      color: #fff!important;
      font-weight: normal;
      line-height:1.5;
      -webkit-box-shadow: none;
      box-shadow: none;
      padding: 1em 0px;
      font-weight: bold;
      &:hover, &:active, &:focus, &:not(.collapsed) {
        color:$orange!important;
        outline:none!important;
        box-shadow:none!important;
        background:transparent!important;
      }
      * {
        margin-bottom:0px;
      }
    }
    .accordion-button:not(.collapsed) {
      color: $orange!important;
      background:transparent!important;
    }
    .accordion-button::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23384660'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E") !important;
      background: $accent;
      padding: 1em;
      border-radius: 1000px;
      font-size: .5em;
      border: solid 5px $accent;
    }
    .accordion-button:not(.collapsed)::after {
      background-image:  url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23384660'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E") !important;
      background: $accent;
    }
  }
}
.accordion-button {
  background:transparent!important;
  box-shadow:none!important;
  outline:none!important;
}
.accordion-button:not(.collapsed) {
  background:transparent!important;
  box-shadow:none!important;
  outline:none!important;
}
