// Theme Colors/Branding
$brand-primary: #384660;
$brand-primary-rgb: rgba($brand-primary,1);
$brand-primary-offset: #497c8e;
$brand-secondary: #ddab42;
$dark: #3E4147;
$dark-alt: #263149;
//$light: #f5f1e3;
$light:#edede3;
$medium: #82b29a;
$inactive: #82b29a;
$gray: #82b29a;
$white: #fff;
$accent: #ddab42;
$accent-2: #d5ab54;
$body-font: #384660;
$error: #d81e00;

// Update BS colors
$primary: $brand-primary;
$secondary: $brand-secondary;
$info: $accent;

$navy: #384660;
$navy-alt: #497c8e;
$green:#80b199;
$red:#b0514a;
$orange:#ddab42;
