// Set custom gutter width
:root {
  --bs-gutter-x: 2rem; // Adjust gutter width here
}

// Container Fluid Adjustments
.container-fluid {
  padding-left: 1rem;
  padding-right: 1rem;

  @include media-breakpoint-up(sm) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @include media-breakpoint-up(xxxl) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

// Box-sizing adjustment to include padding and border in element's total width and height
*,
*::before,
*::after {
  box-sizing: border-box;
}

// Row adjustments to prevent overflow
.row {
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);

  @include media-breakpoint-down(sm) {
    margin-right: calc(var(--bs-gutter-x) * -0.5);  // Use variable for consistency
    margin-left: calc(var(--bs-gutter-x) * -0.5);   // Use variable for consistency
  }
}

// Container adjustments
.container {
  max-width: 1200px;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: auto;
  margin-right: auto;
}

// Optional: overflow hidden on body to prevent overflow issues
body {
  overflow-x: hidden;
}
