//navbar headless shop navigation
#msoLocationDropdown {

	// appearance: auto!important;
	// -webkit-appearance: auto; /* For Safari and other WebKit browsers */
	// -moz-appearance: auto;
	option {
		appearance: auto !important;
		-webkit-appearance: auto;
		/* For Safari and other WebKit browsers */
		-moz-appearance: auto;
		background:$brand-primary;
		color:#fff!important;
		padding:.25em;
		&:hover {
			background:darken($brand-primary,5%);
		}
	}
}

.headless-location-navbar {
	width: 100%;
	max-width: 25vw;
	min-width: 100vw;
	border-radius: 0px;

	@include media-breakpoint-up(md) {
		min-width: 325px;
		border-radius: .5em;
	}

	span.location-navbar-online {
		display: none;
	}

	.headless-location-navbar-select .headless-lns-select select {
		font-size: 13px;
		text-transform: uppercase;
	}
}
//top bar
.current-location {
	@include media-breakpoint-down(xl) {
		padding: .25em 1em!important;
	}
	.headless-menu-navbar {
		.headless-cart-icon {
			background:transparent!important;
		}
		.headless-menu-navbar-select {	
			padding:0px;
			background:transparent;
			.headless-lns-icon svg {
				fill:#fff;
				color:#fff;
			}
			.headless-lns-select .headless-lns-text {
				font-size:10px;
				color:#fff;
				margin-bottom:.25em;
				text-align: left!important;
				margin-left:0!important;
				padding-left: 0;
			}
			.headless-lns-select select {
				font-size:13px;
				color:#fff;
				margin-left:0!important;
				padding-left:0!important;
			}
		}
		.headless-menu-navbar-info {
			margin-left: 2em;
		}
		.headless-menu-navbar-buttons {
			display: none !important;
		}
		.headless-menu-navbar-searchbar-form {
			padding-right: 15px;
			max-width: 120px;
			margin-left: auto;
			@include media-breakpoint-up(md) {
				max-width: 250px;
			}
		}
		.headless-menu-navbar-wrapper {
			align-items: center;
			align-self: stretch;
			display: flex;
			flex-direction: row;
			width: 100%;
			max-width: 100%;
			box-sizing: border-box;
			flex-grow: 1;
		}
	}
	.headless-menu-navbar .headless-menu-navbar-searchbar-form .headless-menu-navbar-searchbar-submit {	
		display:none!important;	
	}
	@media (max-width:374px) {
		.headless-menu-navbar .headless-menu-navbar-searchbar-form {
			display:none!important;
		}
	}
}

.site-footer {
	.headless-menu-navbar {
		.headless-menu-navbar-select {	
			padding:0px;
			background:transparent;
			.headless-lns-icon svg {
				fill:#fff;
				color:#fff;
				width:auto;
			}
			.headless-lns-select .headless-lns-text {
				color:#fff;
				margin-bottom:.25em;
				text-align:left;
			}
			.headless-lns-select select {
				color:#fff;
				min-width:225px;
			}
		}
		.headless-menu-navbar-info {
			margin-left: 0em;
		}
		.headless-menu-navbar-buttons {
			display: inline-block !important;
			margin-left:0;
		}
		.headless-menu-navbar-searchbar-form {
			padding-right: 15px;
			max-width: 200px;
			margin-left: auto;
		}
		.headless-menu-navbar-wrapper {
			align-items: center;
			align-self: stretch;
			display: flex;
			flex-direction: row;
			width: 100%;
			max-width: 100%;
			box-sizing: border-box;
			flex-grow: 1;
		}
	}
}

//headless buttons
.range-headless .headless-shortcode-title-wrapper {
	@include media-breakpoint-up(md) {
		flex-direction: row !important;
		justify-content: space-between !important;
	}
}

.headless-button {
	border-radius: .5em;
	background: transparent;
  color: $brand-primary;
  font-weight: bold;
  border: solid;
	padding:.75em 1em .5em!important;

	&:hover {
		background: darken($light, 10%) !important;
		color: $dark;
		filter: unset !important;
	}

	&.headless-title-button {
		margin-top: 0;
		font-size: 1.25rem !important;
		background: transparent !important;
		color: $dark !important;
		padding-bottom: .5em;
		border-bottom: solid 3px $brand-primary !important;
		border-radius: 0px;
		padding-left: 0px;
		padding-right: 0px;
		text-transform: uppercase;
		font-weight: 800;
		transition: all 0s;

		&::after {
			content: '\2192';
			/* Unicode for the right arrow (→) */
			margin-left: 0.5em;
			display: inline-block;
			/* Ensures proper spacing */
		}

		@include media-breakpoint-up(md) {
			margin-left: auto !important;
			font-size: 1.5rem !important;
		}
	}
}

.headless-location-navbar-shop {
	.headless-button {
		border: solid;

		@include media-breakpoint-down(md) {
			padding: 5px 10px;
		}
	}
}

.white .headless-button.headless-title-button {
	color:$dark!important;
}

.headless-cart-sidebar .headless-cart-sidebar-bottom .headless-cart-sidebar-checkout .headless-cart-button.headless-button,
.headless-cart-page .headless-cart-checkout-button {
	background: $brand-primary !important;
	color:#fff!important;
	border:0;

	&:hover {
		background: lighten($brand-primary, 10%) !important;
		color:#fff!important;
	}
}

//headless typography
.range-headless .headless-shortcode-title {
	font-weight: 800;
	display: block;
	text-align: center;
	text-transform: uppercase;
	color: $body-font;

	@include media-breakpoint-up(md) {
		align-items: center;
		display: flex;
		text-align: left;
	}

	@include media-breakpoint-up(lg) {
		font-size: 1.5em;
	}
}

.background-dark,
.background-primary {
	.range-headless .headless-shortcode-title {
		color:$dark;
	}
}

//headless cards 
.headless-specials-card {
	background: darken($brand-primary,5%)!important;
	border-radius:1em;
	height: 100%;
	padding: 1em 2em!important;
	transition:all .2s ease-out;
	transform:scale(1);
	.headless-specials-card-overlay {
		background:transparent!important;
	}
	&:hover {
		background: darken($brand-primary,8%)!important;
		transition:all .2s ease-out;
		transform:scale(.96);
		.headless-specials-card-overlay {
			background: transparent !important;
			transition: all 0s;
		}
	}
	.headless-specials-title {
		color: $light !important;
		text-shadow: none !important;
		margin-bottom:0!important;
		padding-bottom:0!important;
	}
	.headless-specials-button {
		background: #fff;
		color: $red !important;
		border: 0 !important;
		border-radius:100px;
		text-transform: normal !important;
		transition: all 0s;
		flex-grow: 0 !important;
		flex-shrink: 0 !important;
		transform: scale(.8);
		svg {
			fill:$red!important;
			color:$red!important;
		}
	}
}
.headless-product-list-item {
	background: linear-gradient(45deg, $dark-alt, transparent);
  padding: 1em;
  border-radius: .5em;
	.headless-product-list-image {
		border-radius: .5em;
		background:#fff;
	}
	.headless-product-list-info * {
		color: #fff;
	}
	.headless-product-list-price, .headless-product-list-weight {
		color:#fff!important;
	}
	.headless-button.headless-button-sm {
		background:$light;
	}
	.headless-breadcrumb.headless-product-brands {
		font-weight: normal;
		margin-bottom: .5em;
		color: #fff;
	}
	.headless-list-category {
		border-radius: 0;
		background:transparent!important;
	}
	.product-list-price {
		color:#fff!important;
	}
	.headless-product-special .product-card-special {
		color:#fff!important;
		line-height:1.25em;
		padding:0px;
		.product-card-special-tag {
			display:inline-block!important;
		}
	}
	.headless-product-list-info {
		.headless-product-title {
			font-size: 1em;
			font-weight:bold;
			//color:$dark;
		}
		.headless-product-potency-weight * {
			color: #fff!important;
			font-size: 13px !important;
		}
		.headless-product-list-left .headless-product-list-info .headless-product-title * {
			font-weight:bold!important;
		}
		.headless-product-brands {
			padding-bottom:.05em;
			font-size: 13px;
			line-height: 1.5;
		}
	}
	.headless-product-list-right .headless-add-to-cart {
		margin-left:0px;
	}
	.headless-product-special {
		width: 100%;
    line-height: 1 !important;
    margin-bottom: 10px !important;
    box-shadow: none;
    border:0;	
		font-weight: normal;
		text-transform: uppercase;
		font-size: 13px;
		color:$accent;
		.product-card-special-tag {
			color:$accent;
		}
		svg {
			color:$accent;
			fill:$accent;
			@include media-breakpoint-down(md) {
				display:none;
			}
		}
	}
	.headless-product-list-right .headless-list-discount {
		color:$accent;
	}
	.headless-list-info-details * {
		font-weight:normal;
		background: transparent;
		padding: 0;
		margin: 0;
		margin-right: 0.5em;
		text-transform: none;
		color:#fff;
	}
	.headless-cart-button span:not([class]),
	.headless-cart-button span.headless-cart-item-price__strikethrough {
			display: none;
	}
	@include media-breakpoint-up(md) {
		.headless-cart-button span.headless-pricing-type-special {
			display: none;
		}
	}
}
.headless-product-list-item .headless-product-list-left .headless-product-list-info .headless-product-title, .headless-product-list-item .headless-product-list-left .headless-product-list-info .headless-product-title *  {
	font-weight:bold!important;
	font-size:1.1em;
}

.headless-products-container .headless-product-card {
	border-radius: $border-radius !important;
	border:0;
	transform: scale(1);
	transition: all .2s ease-out;
	&:hover {
		transform: scale(.96);
		transition: all .2s ease-out;
	}
	.headless-product-special {
		//border-radius: var(--headless-border-radius);
		left: 0px;
		padding: .25em;
		position: relative;
		border-radius:.25em!important;
		border:0;
		font-weight:normal;
		@include media-breakpoint-down(md) {
				margin-bottom:5px;
				box-shadow:none;
		}
		&:hover {
			background: #edede3 !important;
			color: $brand-primary !important;
			outline:0!important;
			box-shadow:0!important;
	
			*,
			svg {
				background: #edede3 !important;
				color: $brand-primary !important;
				fill: $brand-primary !important;
			}
		}
		.product-card-special {
			display: inline;
			padding: .5em;
		}
		svg {
			display:none;
		}
	
		* {
			@include media-breakpoint-up(md) {
				color: $red!important;
			}
		}
		svg {
			@include media-breakpoint-down(md) {
				display:none!important;
			}
		}
		.product-card-special-tag {
			font-weight:bold;
			display: block;
			margin-bottom: 0.5em;
			text-transform: uppercase;
			font-size: 13px;
		}
	}
	.headless-product-brands {
		font-size: 12px!important;
		font-weight:bold;
		line-height: 1;
		margin-bottom: 5px;
		text-transform: uppercase;
	}

	.headless-product-bottom .headless-add-to-cart .headless-cart-button {
		background: $red !important;
		color:$light;
		transition: all 0s;
		padding:0!important;
		svg {
			color:$light;
			fill:$light;
		}

		&:hover {
			background: darken($red, 10%) !important;
			transition: all 0s;
			filter: unset !important;
		}

		s.headless-cart-item-price__strikethrough {
			margin-right: .33em;
			font-weight: normal;
			color: $dark;
		}

		.headless-pricing-type-special {
			color: $dark;
		}

		svg {
			fill: $light !important;
			color: $light !important;
		}
	}

	.headless-product-title {
		text-align: left !important;
		justify-content: start !important;
		font-weight:bold;

		a {
			font-weight: 800 !important;
			letter-spacing: .025em !important;
			text-align: left;
		}
	}

	// .headless-product-categories-wrapper {
	//   margin-bottom:.5em;
	// }
	.headless-product-title {
		border-radius: $border-radius-sm;
		font-weight:bold;
	}

	span.headless-strain-icon {
		border-radius: 100px;
		border: solid 1px $dark;
		padding: .25em .5em .15em;
		margin-left:0px;
		color:$dark;
		font-size: 10px;
	}
	.product-card-body {
		background:$light!important;
		//background:#efeee7;
		padding: 15px 15px 15px 45px!important;
		@include media-breakpoint-up(md) {
			padding: 15px!important;
		}
		.headless-product-card-weight {
			background:transparent;
		}
	}
}
.range-headless .headless-pricing-type-special {
	color: $brand-primary;
}	
.headless-shop-tags h1 {
  font-size: 24px;
  color:#fff;
}
.shop .headless-products-container .headless-product-card .product-card-body {
	padding: 15px!important;
	background: #edede3 !important;
}	
.headless-card-theme-v2 .headless-products-container .headless-product-card .headless-card-image-link {
	height:300px!important;
}
.headless-card-theme-v2 .headless-products-container .headless-product-card .headless-card-image-link .headless-product-image {
	max-height:75%;
	margin-top: 25px;
}
.headless-categories {
	.blaze-track {
		padding-bottom:20px!important;
	}
	.headless-category-card {
		justify-content: space-between;
		font-size: 1em !important;
		text-transform: uppercase !important;
		color: $brand-primary !important;
		filter: unset !important;
		border: solid 1px $light !important;
		background:$light;
		border-radius:1em;
		padding:2em 0;
		position:relative;

		@include media-breakpoint-up(md) {
			font-size: 1em !important;
		}
		
		&:before {
			content: '';
			position: absolute;
			bottom: -20px;
			height: 40px;
			width: 40px;
			border-radius: 40px;
			background-color: $red;
			display: flex;
			justify-content: center;
			align-items: center;	
			background-image:url(../../images/cart-plus-white.png);
			background-size: 50%;
			background-position: center;
			background-repeat: no-repeat;
		}

		.headless-product-title {
			font-weight: 800 !important;
			letter-spacing: .025em !important;
			justify-content: start !important;
			margin-top:2em;
		}

		img {
			border-radius:1000px;
			@include media-breakpoint-down(md) {
				max-height:150px;
				max-width:150px;
			}
		}
	}
}
.headless-brands {
	.headless-category-card img {
		max-height: 200px;
		max-width: auto;
		height: auto;
		width: auto;
	}
	.headless-category-card {
		border-radius:1em;
		padding:0px;
		border:0px;
		overflow:hidden;
		justify-content: space-between;
		.headless-product-title {
			font-weight: 800 !important;
			letter-spacing: 0.025em !important;
			-webkit-box-pack: start !important;
			-ms-flex-pack: start !important;
			justify-content: start !important;
			font-size: 14px;
			width: 100%;
			background: $light;
			text-align: center;
			padding:.5em;
		}
	}
	.range-headless .headless-menu-builder-section .headless-slider-wrap .blaze-track {
		padding:0px;
	}
}

.background-dark {
	.headless-specials-card {
		background: #fff !important;

		.headless-specials-title {
			color: $brand-primary !important;
		}

		.headless-specials-button {
			background: $dark !important;

			&:hover {
				opacity: .85;
			}
		}
	}
}

//headless slider 
.headless-slider {
	padding: 0px;
	&:not(.static) {
    padding: 0!important;
  }

	.headless-slider-wrap {
		margin-left: 0px;
		margin-right: 0px;
	}

	.headless-slider-prev {
		height: 40px;
		width: 40px;
		background: $orange !important;
		border-radius: 40px;
		top: 0;
		bottom: 0;
		margin: auto;
		left: -15px !important;
		@include media-breakpoint-up(md) {
			left: -20px !important;
		}

		svg {
			width: 10px !important;
			fill: $light !important;
			color: $light !important;
		}
	}

	.headless-slider-next {
		height: 40px;
		width: 40px;
		background: $orange !important;
		border-radius: 40px;
		top: 0;
		bottom: 0;
		margin: auto;
		right: -15px !important;
		@include media-breakpoint-up(md) {
			right: -20px !important;
		}

		svg {
			width: 10px !important;
			fill: $light !important;
			color: $light !important;
		}
	}
}
@media (max-width:374px) {
	.headless-slider {
		.headless-slider-prev,
		.headless-slider-next {
			transform: scale(.75);
		}
	}	
}


.background-dark {
	.headless-slider .headless-slider-prev svg,
	.headless-slider .headless-slider-next svg {
		color: #555 !important;
		fill: #555 !important;
	}
}
.background-dark {
	.headless-slider .headless-slider-prev svg,
	.headless-slider .headless-slider-next svg {
		color: #555 !important;
		fill: #555 !important;
	}
}

.fixed-bottom-nav {
	position: fixed;
	bottom: 1em;
	width: calc(100vw - 2em);
	border-radius: .5em;
	overflow: hidden;
	margin-left: 1em;
	z-index: 2;
	border: solid 1px #D8D8D8;
	box-shadow: 0px 16px 36px rgba(0, 0, 0, 0.4);

	.headless-menu-navbar {
		background: #fff !important;

		.headless-menu-navbar-buttons {
			margin-left: auto;
			@include media-breakpoint-down(xl) {
				display:none;
			}
		}
	}

	span.headless-oc-open,
	.headless-menu-navbar .headless-menu-navbar-info .headless-menu-navbar-address,
	.headless-menu-navbar-single-location {
		font-size: 13px;
		text-transform: uppercase;
		line-height:1.5;
	}
}

.headless-menu-navbar-buttons {
	@include media-breakpoint-down(xl) {
		display:none!important;
	}
}

.headless-card-theme-v1 .headless-products-container .headless-product-card .headless-product-categories,
.headless-card-theme-v1 .headless-products-container .headless-product-card .headless-product-brands {
	color: $brand-primary !important;
	text-align: left !important;
}

.headless-hr {
	background-color: rgba(0, 0, 0, 0.5) !important;
}

.shop .headless-products-nav--title {
	// @extend .ff-outlined;
	@extend .color-primary;

	@include media-breakpoint-down(md) {
		font-size: 1.5em !important;
		display:none;
	}
}

.range-headless:not(.headless-menu) .headless-products-grid,
.headless-products-container .headless-products-row {
	@include media-breakpoint-down(sm) {
		grid-template-columns: unset !important;
	}

	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(2, 1fr) !important;
	}

	@include media-breakpoint-up(xxl) {
		grid-template-columns: repeat(3, 1fr) !important;
	}

	@include media-breakpoint-up(xxxl) {
		grid-template-columns: repeat(4, 1fr) !important;
	}
    
    @media (min-width: 2500px) {
		grid-template-columns: repeat(5, 1fr) !important;
	}
}

.headless-card-theme-v1 .headless-products-container .headless-product-card {
	padding: 30px;
}

.minimized-deals-layout {
	max-height: 550px !important;
	position: relative;
	overflow-y: auto;

	&:after {
		content: '';
		position: absolute;
		bottom: -25px;
		left: 0;
		height: 50px;
		background: linear-gradient(transparent, #111);
		width: 100%;
	}

	&.expanded {
		max-height: unset !important;
	}

	.headless-product-title,
	.headless-product-bottom,
	.headless-product-special .product-card-special .product-card-special-tag,
	img.headless-product-image {
		display: none !important;
	}

	.range-headless:not(.headless-menu) .headless-products-grid {
		@include media-breakpoint-down(md) {
			grid-template-columns: unset !important;
		}
	}

	a.headless-card-image-link {
		position: absolute;
		top: 0;
		left: 0;
		height: 100% !important;
		width: 100%;
	}

	.headless-product-special {
		box-shadow: unset !important;
	}

	.headless-product-brands {
		margin-bottom: 0px !important;
	}

	.product-card-special * {
		font-size: 1.5em !important;
		display: block;
	}

	.headless-card-theme-v1 .headless-products-container .headless-product-card {
		background: $brand-primary;
		border: 0 !important;
		position: relative;
	}
	.headless-product-special {
		background: transparent !important;
		backdrop-filter: unset !important;
		position: relative !important;
		top: unset !important;
		width: 100% !important;

		svg {
			display: none !important;
		}

		span.product-card-speical-name {
			color:$dark!important;
		}
	}

	.headless-card-theme-v1 .headless-products-container .headless-product-card .headless-product-brands {
		position: relative !important;
		color:$dark!important;
	}

	.headless-card-theme-v1 .headless-products-container .headless-product-card {
		border: 0 !important;
	}
}

//shop
.shop {
	.headless-filter-pill {
		background:transparent;
		color:$brand-primary;
		svg {
			color:$brand-primary;
			fill:$brand-primary;
		}
	}
	.headless-filter-navbar {
		margin-top:2em;
		background:transparent!important;
		.filter-navbar button {
			// background: transparent !important;
			// color: $light !important;
			@include media-breakpoint-down(md) {
				font-size:12px;
			}
		}
		.filter-navbar--mobile .navbar-bottom {
			padding-left:1em;
			padding-right:1em;
			@include media-breakpoint-down(md) {
				* {
					font-size:12px;
				}
			}
		}
	}
	.headless-no-products a {
		color:$dark;
	}
	.headless-filter-sidebar-controls .clear-checkbox-filters-button, .headless-filter-toggle-all {
		background:transparent!important;
		color:#fff!important;
		border-radius:.5em;
		border:solid;
		font-weight:bold;
	}
	.headless-specials {
		display: flex;
		flex-direction: column;
		@include media-breakpoint-up(md) {
			flex-direction: row;
			flex-wrap: wrap;
		}
		a.headless-specials-view-all {
			color: $brand-primary;
			order:2;
			border: solid 1px;
			border-radius: .25em;
			&:hover {
				color:#fff!important;
			}
		}
		.headless-specials-card {
			order:1;
			font-size:1rem;
			padding:.5em 1em !important;
			@include media-breakpoint-up(md) {
				width:48%;
			}
			.headless-specials-title {
				font-size:16px!important;
			}
			.headless-specials-button {
				transform: scale(.75);
			}
		}
	}
	.headless-products-nav .headless-products-nav-dropdown-btn {
		background: transparent;
		color: #fff;
		border-radius: 0.5em;
		font-weight: bold;
		border: solid;
		@include media-breakpoint-down(md) {
			font-size:13px;
		}
		svg {
			color:#fff;
			fill:#fff;
		}
	}
	.headless-products-nav--sort .headless-sort-dropdown {
		background-color: #fff!important;
		width:125px;
		right: 0;
		left: auto;
		margin-top: .5em;
		border-radius: .5em;
		@include media-breakpoint-up(lg) {
			min-width:100px;
			width:auto;
		}
		li:hover {
			background:rgba(255,255,255,0.1)!important;
		}
	}
	.headless-filter-navbar .filter-navbar .headless-pricingtype-select-wrapper .headless-pricingtype-select-title {
		display: none;
	}

	.headless-openorclosed-wrapper .headless-oc-open {
		color: $brand-primary;
	}
	.list-cart-mobile {
		svg {
			color: $brand-primary;
			fill: $brand-primary;
		}
	}
	.headless-shop-container {
		max-width: unset;
	}

	a.headless-back-link,
	.headless-breadcrumb,
	.headless-breadcrumb .sp-potency-name,
	.headless-sp-discount {
		//color:$dark!important;
		font-weight: 800;
	}
	.headless-sp-image-wrapper.col-lg-6.col-xxl-5 {
		width:100%;
		@include media-breakpoint-up(md) {
			width: 33%;
		}
	}
	.headless-sp-image-wrapper + .col-lg-6 {
		width:100%;
		@include media-breakpoint-up(md) {
			width: 66%;
		}
	}
	.headless-single-product .headless-sp-title {
		font-size:1.25rem!important;	
		line-height:1.25em!important;
		letter-spacing:unset!important;
	}
	.headless-single-product {
		.headless-sp-title, .headless-related-products-title,
		.headless-breadcrumb, h2.headless-related-products-title {
			font-size:1.5em!important;	
			line-height:1.25em!important;
			letter-spacing:unset!important;
		}
		.headless-sp-image {
			width:100%;
			img {
				max-width:100%;
				max-height:350px;
			}
		}
	}

	.headless-breadcrumb {
		border-bottom: 0;
		border-radius: 0px;
	}
	
	.headless-sp-categories .headless-list-category {
		padding: 0.7em 1em .5em !important;
		text-transform: uppercase;
		border-radius: .5em !important;
	}
	.headless-product-list-item .headless-product-list-weight {
		color:$dark;
		background:transparent;
	}
	
	.filter-navbar-categories {
		[aria-pressed="true"], .active {
			font-size: 1em !important;
			text-transform: uppercase;
			color: $brand-primary !important;
			@include media-breakpoint-up(md) {
				font-size: 1.15em !important;
			}
		}
	}

	.headless-filter-navbar {
		padding: 5px 0px !important;
		@include media-breakpoint-up(lg) {
			padding:0px 40px !important;
		}
	}

	.range-headless .headless-font-normal {
		font-weight: 800 !important;
		//color:$dark!important;
		-webkit-text-stroke: 0 !important;
	}

	.headless-filter-navbar {
		border-bottom: unset !important;
		background: #edede3 !important;
		margin: 0;
		border-radius:.5em;
		width: calc(100% - 1em);
		margin-left: auto;
		margin-right: auto;
		margin-bottom:2.25em;
		left: 0;
		z-index:2;
		@include media-breakpoint-up(md) {
			top: 121px;
		}
	}

	.headless-single-product .headless-sp-variation-price {
		font-weight: 800;

		.headless-cart-item-price__strikethrough,
		.headless-sp-discount {
			font-weight: normal;
		}
	}
	
	//sidebar
	.headless-mobile-filter-search .headless-searchbar {
		background:$dark-alt;
	}
	.headless-mobile-filter-specials .specials-toggle-wrapper .toggle-label-text {
		color:#fff;
	}
	#filter-sidebar-content {
		.headless-filter-navbar .filter-navbar .headless-pricingtype-select-wrapper .headless-pricingtype-select {
			border: 0;
			font-weight: bold;
			padding: .5em;
			background: transparent;
			color:$dark!important;
			margin-left: .5em;
		}
		.headless-filter-sidebar .filter-sidebar-module .filter-sidebar-options .filter-option label {
			font-size: 13px;
			font-weight: normal;
			line-height: 1.5em;
		}
		.specials-toggle-wrapper .toggle-label-text {
			color:#fff;
		}
		.filter-sidebar-module {
			border-bottom:solid 0px!important;
			background: $dark-alt;
			padding: 1em!important;
			border-radius: .5em;
			margin-bottom: 1em;
			border-bottom:solid 0px!important;
			color:#fff;
			.filter-sidebar-num-range-input-label {
				color:#fff;
			}
			
			.filter-sidebar-header svg {
				filter:invert(1);
			}
			.filter-sidebar-options-checkboxes {
				grid-template-columns: unset;
				.filter-option label {
					font-size: 1rem;
					font-weight: bold;
					line-height: 1.5em;
				}
			}
		}
		.headless-filter-pill {
			background: transparent;
			color: #fff;
			border: 0;
			padding: 0;
			margin-right: .5em;
			
			svg {
				color:#fff;
				fill:#fff;
			}
		}
		.headless-searchbar {
			background: $dark-alt;
			&:before {
				left:1em!important;
			}
			.headless-nav-searchbar-input {
				margin-left: 2em;
			}
		}
	}
}

// single product
.headless-single-product {
	.headless-sp-title, .headless-related-products-title,
	.headless-breadcrumb, h2.headless-related-products-title {
		font-size:1.5em!important;	
		line-height:1.25em!important;
		letter-spacing:unset!important;
	}
	.headless-sp-image {
		width:100%;
		img {
			max-width:100%;
			max-height:350px;
			margin-bottom: 1em;
		}
	}
	[aria-labelledby="related-products-heading"] {
		@include media-breakpoint-up(md) {
			padding: 2em;
		}
	}
	.headless-sp-variation-price {
		font-weight: 800;
		.headless-cart-item-price__strikethrough,
		.headless-sp-discount {
			font-weight: normal;
		}
	}
	.headless-single-product .headless-sp-title {
		line-height: 1em;
	
		@include media-breakpoint-up(md) {
			font-size: 44px !important;
		}
	}
	
	.headless-breadcrumb,
	.headless-sp-category a {
		color: $brand-primary !important;
	}
	.headless-breadcrumb, .headless-related-products-title {
		font-size: 1.5em !important;
	}
}
.headless-single-product .headless-sp-title {
	line-height: 1.5em;

	@include media-breakpoint-up(md) {
		font-size: 1.25rem !important;
	}
}

.headless-single-product .headless-breadcrumb,
.headless-single-product .headless-sp-category a {
	color: $brand-primary !important;
}
.headless-single-product {
	.terpene-list-data .terpene-list:nth-child(2),
	.headless-sp-cannabinoid-more,
	.headless-sp-cannabinoid-chart,
	.headless-sp-terpenes .headless-sp-terpene-more,
	.headless-sp-cannabinoids {
		display:none;
	}
	.headless-sp-terpenes .headless-terpene-description,
	.headless-product-special-details {
		border-radius:1em;
	}
	article.headless-sp-primary {
		display:flex;
		flex-direction:column;
		h1#product-title {
			order: 1;
			font-size: 1.75em !important;
		}
		.headless-sp-brand {
			order:2;
			margin-top:1em;
		}
		.headless-sp-category-bar {
			order: 3;
			margin-top:1em;
		}
		.headless-sp-description {
			order:4;
			margin-top:1em;
		}
		.headless-sp-variation-price {
			order:5;
		}		
		.headless-sp-add-cart {
			order:6;
		}
		.headless-sp-variations {
			order:7;
		}
		.headless-product-special-details {
			order:8;
		}
		.headless-sp-potency {
			order:9;
		}
		.headless-sp-cannabinoids {
			order:10;
		}
		.headless-sp-terpenes {
			order:11;
		}
	}
}
.brands {
	.headless-menu-builder-section {
		column-count: 2;

		@include media-breakpoint-up(md) {
			column-count: 3;
		}

		@include media-breakpoint-up(lg) {
			column-count: 4;
		}

		@include media-breakpoint-up(xl) {
			column-count: 5;
		}

		@include media-breakpoint-up(xxl) {
			column-count: 6;
		}
	}

	.headless-category-card {
		border: solid 1px #fff !important;
		display: inline-block;
		text-align: center;

		&:hover {
			box-shadow: 0 !important;
			border: solid 1px #ccc !important;
		}

		.headless-product-title {
			font-size: 13px !important;
		}
	}
}

.headless-location-modal-content {
	max-width: 800px;
	max-height: 100vh;
	height: 100vh;
  background:transparent;
	.headless-location-gate-state {
		&:last-of-type {
			border-bottom: 0;
		}
	}
}
.headless-location-modal-overlay {
	justify-content:end;
}
.headless-location-modal-overlay.hlm-visible:after {
	content:'';
	position:absolute;
	height:100vh;
	width:100vw;
	top:0px;
	left:0px;
	background: linear-gradient(90deg, rgba(56, 70, 96,.85), rgb(56, 70, 96))!important;
	z-index:-1;
}
button.headless-button.headless-agegate-yes {
	@extend .btn-accent-2;
}
button.headless-button.headless-agegate-no {
	background:transparent!important;
	border:2px solid white;
	color:white!important;
	&:hover {
		background:white!important;
		color:$brand-primary!important;
	}
}
.headless-location-modal-content .headless-hlm-locations .headless-hlm-location {
	background:$light;
	border-radius:1em;
	margin-bottom:1em!important;
	transform:scale(1);
	&:hover {
		border:0!important;
		transform:scale(1.0125);
	}
}

.headless-cart-icon-fixed {
  background:$orange;
	svg {
		color:$brand-primary;
		fill:$brand-primary;
	}
}

//CART
#cart-sidebar  {
	.headless-cart-sidebar-table {
		background: #fff;
	}
	
	.headless-cart-sidebar-contents {
		.headless-cart-sidebar-item {
			* {
				color:$dark!important;
			}
			.cart-variant-single > div {
				color:#fff!important;
				.headless-cart-item-price {
					color:#fff!important;
				}
				select {
					color:$dark!important;
				}
			}
		}
	}
}

//CHECKOUT

#cart, #cart-sidebar, .headless-cart-container .headless-cart-page {
	.cart-retailer-info__todays-hours {
		* {
			color:$brand-primary!important;
		}
	}
	.cart-retailer-info {
		@include media-breakpoint-down(lg) {
			text-align:center!important;
		}
	}
	.headless-cart-left {
		background:#fff;
		border-radius: 1em;
	}
	.headless-cart-right {
		border-radius: 1em;
	}
	.headless-cart-sidebar-table .cart-variant-single .headless-cart-item-price {
		color:$brand-primary!important;
	}
	.headless-cart-ordertype {
		background:$brand-primary;
		border-radius:.5em;
		margin:1em;
		
	}
	.headless-cart-ordertype .ordertype-option {
		input:not(:checked) + label {
			background: #fff !important;
			* {
				color: $brand-primary !important;
				fill: $brand-primary !important;
			}
			&:hover {
				background: $dark !important;
				color:$dark!important;
				* {
					color:#fff!important;
					fill: #fff !important;
				}
			}
		}
		input:checked + label {
			background: $brand-primary !important;
			color:#fff!important;
			* {
				color:#fff!important;
				fill: #fff !important;
			}
		}
		// Ensure the hover state is applied correctly
		label:hover {
			background: $brand-primary !important;
			color:#fff!important;
			* {
				color:$dark!important;
				fill: #fff !important;
			}
		}
	}
}

.headless-menu-navbar {
  background: transparent;
  color:#fff!important;
  padding: 0;
  font-size: 13px;
}

.headless-searchbar {
  position: relative;
  background: rgb(57 70 96);
  padding: .5em;
  border-radius: .5em;
	@include media-breakpoint-down(md) {
		margin-bottom:2em;
	}
	
	.clear-search-icon {
		filter:invert(1);
		display:none!important;
	}

  &::before {
    content: "";
    background-image: url(../../images/search-regular-white.png);
    position: absolute;
    left: 10px; /* Adjust as needed */
    top: 50%;
    transform: translateY(-50%);
		@include media-breakpoint-down(md) {
			left: 10px; /* Adjust as needed */
		}
  }

  .headless-nav-searchbar-input {
    padding: 0 0 0 2.5em;
    font-size: 11px;
    color:#fff;
		border:0!important;
		@include media-breakpoint-up(xl) {
			font-size: 13px;	
			width:unset;
		}
    &::placeholder {
      color:#fff;
    }

    &:focus,
    &:hover,
    &:active {
      background: transparent;
      color:#fff;
			padding-left:2.5em;
			border:0;
    }
  }
}
p.headless-no-products {
  color:$dark;
}
body:not(.shop) {
	p.headless-no-products {
		color:#fff;
	}
}

.single-product-pop {
	padding:20px 20px 50px;
	background:#fff;
	max-width:600px;
	margin: auto;
	border-radius: 1em;
	div#related-products-grid {
		padding-left: 1em;
		padding-right: 1em;
	}
	.headless-sp-image-wrapper.col-lg-6.col-xxl-5, .headless-sp-image-wrapper + .col-lg-6, .headless-single-product .headless-sp-image {
		width: 100%!important;
	}
	.headless-products-container {
		.blaze-track {
			@include media-breakpoint-up(md) {
			--slide-width: 48%!important;
			}
		}
	}
	h2.headless-related-products-title {
		font-size:1.5em!important;	
		line-height:1.25em!important;
		letter-spacing:unset!important;
	}
	.sp-potency-single .headless-breadcrumb.sp-potency-name {
		padding-right: 3em !important;
	}
}
.single-product-static,
.single-product-pop {
	[aria-labelledby="related-products-heading"] {
		padding:2em;
	}
}
div#singleProductPop {
  background: #fff;
  border-radius: 1em;
  color: #000;
}

.page-shop-data .headless-single-product-nav {
	top: 0px;
}

.headless-menu-banner-single {
  border-radius: 1em !important;
  overflow: hidden;
}
.headless-product-list-hr {
	background: transparent;
	height: 1px;
	margin: .5em 0;
}	

.headless-menu-banners {
	max-width:100%;
	overflow:hidden;
	.headless-banner-slider {
		max-width:100%;
		.headless-slider-prev {
			left: -20px;
			background:transparent!important;
			svg {
				transform:scale(.5);
			}
		}
		.headless-slider-next {
			right: -20px;
			background:transparent!important;
			svg {
				transform:scale(.5);
			}
		}
	}
}

.site-footer .headless-menu-navbar .headless-menu-navbar-info {
	text-align:left;
}

.show-categories {
  position: fixed;
  top: 120px;
  width: 100vw;
  left: 0;
	font-weight:bold;
	text-align:center;
	z-index:1;
}
.shop .headless-filter-navbar.position-fixed {
	top: 167px;
	left:0px;
	border-radius: 0px;
	width: 100vw;
	z-index:1;
	position:fixed;
}

//mobile filters
.headless-filter-wrapper.filter-toggled {
	@include media-breakpoint-down(lg) {
		transform: translateY(0);
		background: #38445b;
	}
}
.headless-filter-sidebar-controls {
		@include media-breakpoint-down(lg) {
		background:#313d54;
		.apply-checkbox-filters-button {
			border-radius:.5em!important;
			background:$light;
			color:$brand-primary;
			border:0;
		}
	}
}
