/*
** Custom animations
*/

// CSS only background image fade slider
@keyframes bg-fade-out {
  33% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
}

// Increase AOS Duration && Delay
[data-aos] {
  @for $i from 1 through 200 {
    body[data-aos-duration='#{$i * 50}'] &,
    &[data-aos][data-aos-duration='#{$i * 50}'] {
      transition-duration: #{$i * 50}ms;
    }

    body[data-aos-delay='#{$i * 50}'] &,
    &[data-aos][data-aos-delay='#{$i * 50}'] {
      transition-delay: 0;

      &.aos-animate {
        transition-delay: #{$i * 50}ms;
      }
    }
  }
}

//particle sound visualizer
#particles-js {
  position: absolute;
  top: calc(-1em); /* Dynamic margin based on the surrounding container */
  left: calc(-1em);
  width: calc(100% + 2em); /* Adjust width to be slightly larger than the container */
  height: calc(100% + 2em); /* Adjust height dynamically */
  border-radius: 50%; /* Keeps the shape proportional */
  overflow: hidden;
  z-index: -1; /* Behind the box */
  margin: auto;
  pointer-events: none; /* Disable interactions with the particles */
  opacity: 0.35;
  animation: musicBeat 2.3s infinite;
}
.particle-box {
  position: relative;
  z-index: 2; /* In front of the particles */
  margin: auto; /* Center the box inside the container */
  display:inline-block!important;
}
.color-white {
  color:#fff;
}

@keyframes musicBeat {
  0% {
    transform: scale(1); /* Start at normal size */
  }
  10% {
    transform: scale(1.1); /* Slight increase */
  }
  20% {
    transform: scale(0.95); /* Slight decrease */
  }
  30% {
    transform: scale(1.15); /* Larger increase to match a beat */
  }
  40% {
    transform: scale(0.98); /* Drop down but not too low */
  }
  50% {
    transform: scale(1.2); /* Max increase */
  }
  60% {
    transform: scale(1); /* Back to normal */
  }
  70% {
    transform: scale(1.1); /* Gradual increase */
  }
  80% {
    transform: scale(0.9); /* Drop to simulate softer beats */
  }
  90% {
    transform: scale(1.15); /* Another big beat pulse */
  }
  100% {
    transform: scale(1); /* End where we started for seamless loop */
  }
}
.pulse {
  display: inline-block;
  width: 100px;
  height: 100px;
  background-color: #497c8e;
  border-radius: 50%;
  animation: musicBeat 2s infinite; /* Run the animation infinitely */
  transform-origin: center; /* Ensure scaling happens from the center */
}


//glitch
.glitch-text {
  text-align: center;
  position: relative;
  display: inline-block;
  color: white; /* Set text color to white */
  &:before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    transform:translateX(2px);
    left:0px;
    color: white; /* Keep color white */
    text-shadow: -1px 0 #ff00ff; /* Change shadow to more visible neon pink */
    clip: rect(0, 1400px, 0, 0);
    animation: glitch-1 1s linear alternate-reverse infinite!important;
  }
  &:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left:0px;
    transform: translateX(-2px);
    color: lighten(#00FFFF, 33%); /* Lighter blue color for contrast */
    text-shadow: 3px 0 #00FF00; /* Green shadow for extra contrast */
    clip: rect(0, 1400px, 0, 0);
    animation: glitch-2 .6s linear alternate-reverse infinite .2s!important;
  }
}

@keyframes glitch-1 {
  0% {
    clip: rect(40px, 1400px, 70px, 0px);
  }
  15% {
    clip: rect(130px, 1400px, 131px, 0px);
  }
  50% {
    clip: rect(90px, 1400px, 96px, 0px);
  }
  75% {
    clip: rect(125px, 1400px, 185px, 0px);
  }
  87% {
    clip: rect(70px, 1400px, 100px, 0px);
  }
  100% {
    clip: rect(130px, 1400px, 130px, 0px);
  }
}

@keyframes glitch-2 {
  0% {
    clip: rect(20px, 1400px, 80px, 0px);
  }
  15% {
    clip: rect(100px, 1400px, 105px, 0px);
  }
  50% {
    clip: rect(100px, 1400px, 95px, 0px);
  }
  75% {
    clip: rect(60px, 1400px, 60px, 0px);
  }
  87% {
    clip: rect(145px, 1400px, 160px, 0px);
  }
  100% {
    clip: rect(185px, 1400px, 185px, 0px);
  }
}
