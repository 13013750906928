/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/* Gravity Forms Styling */

.gform_legacy_markup_wrapper textarea.large {
  @include media-breakpoint-up(md) {
    height:150px!important;
  }
}

.gform_legacy_markup_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
.gform_legacy_markup_wrapper textarea.large, .gform_legacy_markup_wrapper select.large, .gform_wrapper.gravity-theme input[type=color], .gform_wrapper.gravity-theme input[type=date], .gform_wrapper.gravity-theme input[type=datetime-local], .gform_wrapper.gravity-theme input[type=datetime], .gform_wrapper.gravity-theme input[type=email], .gform_wrapper.gravity-theme input[type=month], .gform_wrapper.gravity-theme input[type=number], .gform_wrapper.gravity-theme input[type=password], .gform_wrapper.gravity-theme input[type=search], .gform_wrapper.gravity-theme input[type=tel], .gform_wrapper.gravity-theme input[type=text], .gform_wrapper.gravity-theme input[type=time], .gform_wrapper.gravity-theme input[type=url], .gform_wrapper.gravity-theme input[type=week], .gform_wrapper.gravity-theme select, .gform_wrapper.gravity-theme textarea {
  appearance: none;
  font-family: inherit;
  font-size: 1rem;
  font-weight:bold;
  letter-spacing: normal;
  padding: 10px!important;
  border-radius:.5em;
  border:0;
  background:rgba(0,0,0,0.1);
  color:#fff;
}
.gform_footer {
@extend .d-flex;
@extend .justify-content-start;
}
.gform_legacy_markup_wrapper .gform_footer input[type=submit] {
  width:auto!important;
  
}
.gform_footer, .gform_page_footer{
  input[type=submit], input[type=button] {
    @extend .btn;
    @extend .btn-light;
  }
}

.white-label {
  .gfield_description, label.gform-field-label.gform-field-label--type-sub, h3.gsection_title, .gform_wrapper.gravity-theme .gfield_label, .gf_progressbar_title {
    color: #fff!important;
  }
}
.gform_wrapper.gravity-theme .gf_progressbar_blue {
  background: rgba(0,0,0,0.1);
}
.gform_wrapper.gravity-theme .gf_progressbar_percentage.percentbar_blue {
  background-color: #ddab42;
  color: #fff;
}

.gform_wrapper.gravity-theme .gfield_validation_message, .gform_wrapper.gravity-theme .validation_message {
  background:transparent;
  border-color:$red;
}
.gform_wrapper.gravity-theme .gfield_error .gfield_repeater_cell label, .gform_wrapper.gravity-theme .gfield_error label, .gform_wrapper.gravity-theme .gfield_error legend, .gform_wrapper.gravity-theme .gfield_validation_message, .gform_wrapper.gravity-theme .validation_message, .gform_wrapper.gravity-theme [aria-invalid=true]+label, .gform_wrapper.gravity-theme label+[aria-invalid=true] {
  color:$red;
}
